@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
    font-family: "Montserrat", sans-serif;
    color: #2a303b;
    font-size: 15px
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Montserrat", sans-serif;
    line-height: 1.4
}

a {
    text-decoration: none;
    outline: none
}

p {
    line-height: 1.6
}

html {
    scroll-padding-top: 40px;
    scroll-behavior: smooth
}

.row > * {
    position: relative
}

.navbar {
    transition: all .5s ease;
    padding: 10px 0px;
    background-color: #fff;
    box-shadow: 0 0px 10px rgba(0, 0, 0, .15)
}

.navbar .navbar-nav .nav-item .nav-link {
    color: #6c757d;
    font-size: 13px;
    letter-spacing: .05rem;
    font-weight: 700;
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, 0);
    margin: 0px 6px;
    transition: all .3s ease;
    font-family: "Roboto", sans-serif
}

.navbar .navbar-nav .nav-item .nav-link:hover, .navbar .navbar-nav .nav-item .nav-link:active, .navbar .navbar-nav .nav-item .nav-link.active {
    color: #d3409e !important
}

.navbar .phone-call .nav-link {
    color: #d3409e;
    padding: .5rem 1rem
}

.navbar .navbar-toggler {
    padding: 0px;
    border: none;
    font-size: 24px
}

.navbar .navbar-toggler:focus {
    box-shadow: none
}

.navbar .navbar-brand .logo-light {
    display: inline-block
}

.navbar .navbar-brand .logo-dark {
    display: none
}

@media (max-width: 991.98px) {
    .navbar .navbar-brand .logo-light {
        display: none
    }

    .navbar .navbar-brand .logo-dark {
        display: block
    }
}

.nav-sticky.navbar {
    background: #fff;
    padding: 15px 0px;
    margin-top: 0px;
    box-shadow: 0px 3px 10px 0px rgba(42, 48, 59, .08)
}

.nav-sticky .navbar-brand .logo-dark {
    display: inline-block
}

.nav-sticky .navbar-brand .logo-light {
    display: none
}

@media (min-width: 992px) {
    .navbar-light .navbar-nav .nav-item .nav-link {
        color: rgba(255, 255, 255, .7);
        font-family: "Roboto", sans-serif
    }

    .navbar-light .navbar-nav .nav-item .nav-link:hover, .navbar-light .navbar-nav .nav-item .nav-link:active, .navbar-light .navbar-nav .nav-item .nav-link.active {
        color: #fff !important
    }

    .navbar-light .phone-call .nav-link {
        color: #fff
    }

    .nav-sticky.navbar-light {
        background: #fff
    }

    .nav-sticky.navbar-light .nav-item .nav-link {
        color: #2a303b
    }

    .nav-sticky.navbar-light .nav-item .nav-link:hover, .nav-sticky.navbar-light .nav-item .nav-link.active, .nav-sticky.navbar-light .nav-item .nav-link:active {
        color: #d3409e !important
    }

    .nav-sticky.navbar-light .phone-call .nav-link {
        color: #d3409e
    }
}

@media (max-width: 991px) {
    .navbar {
        padding: 12px 0px;
        background-color: #fff
    }
}

.btn {
    padding: 12px 34px;
    font-size: 15px;
    font-weight: 500;
    transition: all .5s ease;
    position: relative
}

.btn:focus {
    box-shadow: none
}

.btn-primary {
    background: #d3409e;
    border-color: #d3409e !important
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .btn-primary.focus, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary .open > .dropdown-toggle.btn-primary {
    background: #214f9a;
    border-color: #214f9a;
    box-shadow: 0px 8px 20px -6px rgba(37, 88, 171, .6);
    transform: translateY(-6px)
}

.btn-outline-primary {
    color: #d3409e;
    border-color: #d3409e
}

.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.focus, .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary .open > .dropdown-toggle.btn-primary {
    background: #d3409e;
    border-color: #d3409e;
    box-shadow: 0px 8px 20px -6px rgba(37, 88, 171, .6);
    transform: translateY(-6px)
}

.btn-secondary {
    background: #d3409e;
    border-color: #d3409e !important
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .btn-secondary.active, .btn-secondary.focus, .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary .open > .dropdown-toggle.btn-primary {
    background: #ff703d;
    border-color: #ff703d;
    box-shadow: 0px 8px 20px -6px rgba(255, 127, 81, .6);
    transform: translateY(-6px)
}

.btn-outline-secondary {
    color: #d3409e;
    border-color: #d3409e
}

.btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.focus, .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary .open > .dropdown-toggle.btn-primary {
    background: #d3409e;
    border-color: #d3409e;
    box-shadow: 0px 8px 20px -6px rgba(255, 127, 81, .6);
    transform: translateY(-6px)
}

.btn-success {
    background: #1abc9c;
    border-color: #1abc9c !important
}

.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .btn-success.focus, .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success .open > .dropdown-toggle.btn-primary {
    background: #18aa8d;
    border-color: #18aa8d;
    box-shadow: 0px 8px 20px -6px rgba(26, 188, 156, .6);
    transform: translateY(-6px)
}

.btn-outline-success {
    color: #1abc9c;
    border-color: #1abc9c
}

.btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.focus, .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success .open > .dropdown-toggle.btn-primary {
    background: #1abc9c;
    border-color: #1abc9c;
    box-shadow: 0px 8px 20px -6px rgba(26, 188, 156, .6);
    transform: translateY(-6px)
}

.btn-info {
    background: #49c6e5;
    border-color: #49c6e5 !important
}

.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .btn-info.focus, .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info .open > .dropdown-toggle.btn-primary {
    background: #37c0e2;
    border-color: #37c0e2;
    box-shadow: 0px 8px 20px -6px rgba(73, 198, 229, .6);
    transform: translateY(-6px)
}

.btn-outline-info {
    color: #49c6e5;
    border-color: #49c6e5
}

.btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.focus, .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info .open > .dropdown-toggle.btn-primary {
    background: #49c6e5;
    border-color: #49c6e5;
    box-shadow: 0px 8px 20px -6px rgba(73, 198, 229, .6);
    transform: translateY(-6px)
}

.btn-warning {
    background: #ffcd00;
    border-color: #ffcd00 !important
}

.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .btn-warning.focus, .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning .open > .dropdown-toggle.btn-primary {
    background: #ebbd00;
    border-color: #ebbd00;
    box-shadow: 0px 8px 20px -6px rgba(255, 205, 0, .6);
    transform: translateY(-6px)
}

.btn-outline-warning {
    color: #ffcd00;
    border-color: #ffcd00
}

.btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.focus, .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning .open > .dropdown-toggle.btn-primary {
    background: #ffcd00;
    border-color: #ffcd00;
    box-shadow: 0px 8px 20px -6px rgba(255, 205, 0, .6);
    transform: translateY(-6px)
}

.btn-danger {
    background: #ef4b4b;
    border-color: #ef4b4b !important
}

.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active, .btn-danger.focus, .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger .open > .dropdown-toggle.btn-primary {
    background: #ed3838;
    border-color: #ed3838;
    box-shadow: 0px 8px 20px -6px rgba(239, 75, 75, .6);
    transform: translateY(-6px)
}

.btn-outline-danger {
    color: #ef4b4b;
    border-color: #ef4b4b
}

.btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.focus, .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger .open > .dropdown-toggle.btn-primary {
    background: #ef4b4b;
    border-color: #ef4b4b;
    box-shadow: 0px 8px 20px -6px rgba(239, 75, 75, .6);
    transform: translateY(-6px)
}

.btn-light {
    background: #f8f9fa;
    border-color: #f8f9fa !important
}

.btn-light:hover, .btn-light:focus, .btn-light:active, .btn-light.active, .btn-light.focus, .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light .open > .dropdown-toggle.btn-primary {
    background: #eceff2;
    border-color: #eceff2;
    box-shadow: 0px 8px 20px -6px rgba(248, 249, 250, .6);
    transform: translateY(-6px)
}

.btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-outline-light:hover, .btn-outline-light:focus, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.focus, .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light .open > .dropdown-toggle.btn-primary {
    background: #f8f9fa;
    border-color: #f8f9fa;
    box-shadow: 0px 8px 20px -6px rgba(248, 249, 250, .6);
    transform: translateY(-6px)
}

.btn-dark {
    background: #2a303b;
    border-color: #2a303b !important
}

.btn-dark:hover, .btn-dark:focus, .btn-dark:active, .btn-dark.active, .btn-dark.focus, .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark .open > .dropdown-toggle.btn-primary {
    background: #22262f;
    border-color: #22262f;
    box-shadow: 0px 8px 20px -6px rgba(42, 48, 59, .6);
    transform: translateY(-6px)
}

.btn-outline-dark {
    color: #2a303b;
    border-color: #2a303b
}

.btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.focus, .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark .open > .dropdown-toggle.btn-primary {
    background: #2a303b;
    border-color: #2a303b;
    box-shadow: 0px 8px 20px -6px rgba(42, 48, 59, .6);
    transform: translateY(-6px)
}

.btn-muted {
    background: #9da9bb;
    border-color: #9da9bb !important
}

.btn-muted:hover, .btn-muted:focus, .btn-muted:active, .btn-muted.active, .btn-muted.focus, .btn-muted:not(:disabled):not(.disabled):active, .btn-muted:not(:disabled):not(.disabled):active:focus, .btn-muted .open > .dropdown-toggle.btn-primary {
    background: #919eb3;
    border-color: #919eb3;
    box-shadow: 0px 8px 20px -6px rgba(157, 169, 187, .6);
    transform: translateY(-6px)
}

.btn-outline-muted {
    color: #9da9bb;
    border-color: #9da9bb
}

.btn-outline-muted:hover, .btn-outline-muted:focus, .btn-outline-muted:active, .btn-outline-muted.active, .btn-outline-muted.focus, .btn-outline-muted:not(:disabled):not(.disabled):active, .btn-outline-muted:not(:disabled):not(.disabled):active:focus, .btn-outline-muted .open > .dropdown-toggle.btn-primary {
    background: #9da9bb;
    border-color: #9da9bb;
    box-shadow: 0px 8px 20px -6px rgba(157, 169, 187, .6);
    transform: translateY(-6px)
}

.btn-white {
    background: #ffffff;
    border-color: #ffffff !important
}

.btn-white:hover, .btn-white:focus, .btn-white:active, .btn-white.active, .btn-white.focus, .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white .open > .dropdown-toggle.btn-primary {
    background: #f5f5f5;
    border-color: #f5f5f5;
    box-shadow: 0px 8px 20px -6px rgba(255, 255, 255, .6);
    transform: translateY(-6px)
}

.btn-outline-white {
    color: #ffffff;
    border-color: #ffffff
}

.btn-outline-white:hover, .btn-outline-white:focus, .btn-outline-white:active, .btn-outline-white.active, .btn-outline-white.focus, .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white .open > .dropdown-toggle.btn-primary {
    background: #fff;
    border-color: #fff;
    box-shadow: 0px 8px 20px -6px rgba(255, 255, 255, .6);
    transform: translateY(-6px)
}

.btn.btn-outline-white:focus, .btn.btn-outline-white:active, .btn.btn-outline-white:hover, .btn.btn-outline-white.active, .btn.btn-outline-light:focus, .btn.btn-outline-light:active, .btn.btn-outline-light:hover, .btn.btn-outline-light.active {
    color: #2a303b
}

.btn.btn-outline-primary:focus, .btn.btn-outline-primary:active, .btn.btn-outline-primary:hover, .btn.btn-outline-primary.active, .btn.btn-outline-dark:focus, .btn.btn-outline-dark:active, .btn.btn-outline-dark:hover, .btn.btn-outline-dark.active, .btn.btn-outline-success:focus, .btn.btn-outline-success:active, .btn.btn-outline-success:hover, .btn.btn-outline-success.active {
    color: #fff !important
}

.text-primary {
    color: #d3409e !important
}

.bg-primary {
    background-color: #d3409e !important
}

.text-secondary {
    color: #d3409e !important
}

.bg-secondary {
    background-color: #d3409e !important
}

.text-success {
    color: #1abc9c !important
}

.bg-success {
    background-color: #1abc9c !important
}

.text-info {
    color: #49c6e5 !important
}

.bg-info {
    background-color: #49c6e5 !important
}

.text-warning {
    color: #ffcd00 !important
}

.bg-warning {
    background-color: #ffcd00 !important
}

.text-danger {
    color: #ef4b4b !important
}

.bg-danger {
    background-color: #ef4b4b !important
}

.text-light {
    color: #f8f9fa !important
}

.bg-light {
    background-color: #f8f9fa !important
}

.text-dark {
    color: #2a303b !important
}

.bg-dark {
    background-color: #2a303b !important
}

.text-muted {
    color: #9da9bb !important
}

.bg-muted {
    background-color: #9da9bb !important
}

.text-white {
    color: #fff !important
}

.bg-white {
    background-color: #fff !important
}

.section {
    padding-top: 80px;
    padding-bottom: 80px;
    position: relative
}

.fs-12 {
    font-size: 12px
}

.fs-13 {
    font-size: 13px
}

.fs-14 {
    font-size: 14px
}

.fs-15 {
    font-size: 15px
}

.fs-16 {
    font-size: 16px
}

.fs-17 {
    font-size: 17px
}

.fs-18 {
    font-size: 18px
}

.fs-19 {
    font-size: 19px
}

.fs-20 {
    font-size: 20px
}

.fw-medium {
    font-weight: 500
}

.fw-semibold {
    font-weight: 600
}

.box-shadow {
    box-shadow: rgba(0, 0, 0, .05) 0px 8px 24px
}

.bg-overlay {
    position: absolute;
    background-color: rgba(42, 48, 59, .7);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0
}

.form-control {
    padding: 10px 14px;
    font-size: 14px;
    border: 1px solid rgba(42, 48, 59, .2)
}

.form-control:focus {
    box-shadow: none;
    border-color: rgba(42, 48, 59, .3)
}

input::-webkit-input-placeholder, .form-control::-webkit-input-placeholder {
    color: #ced4da
}

input::-moz-placeholder, .form-control::-moz-placeholder {
    color: #ced4da
}

input:-ms-input-placeholder, .form-control:-ms-input-placeholder {
    color: #ced4da
}

input:-moz-placeholder, .form-control:-moz-placeholder {
    color: #ced4da
}

#back-to-top {
    width: 30px;
    height: 30px;
    position: fixed;
    bottom: 20px;
    right: 30px;
    background: #2a303b;
    border-color: rgba(0, 0, 0, 0);
    border-radius: 3px;
    color: #fff;
    transition: all .5s ease;
    display: none
}

#back-to-top:hover {
    background-color: #d3409e
}

.primary-link {
    color: #2a303b;
    transition: all .5s ease
}

.primary-link:hover {
    color: #d3409e
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px !important
    }
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to top left, #d3409e 0%, #49c6e5 100%);
    z-index: 9999999
}

#preloader #status {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateY(-50%)
}

.spinner {
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
    text-align: center;
    animation: sk-rotate 2s infinite linear
}

.dot1, .dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: #fff;
    border-radius: 100%;
    animation: sk-bounce 2s infinite ease-in-out
}

.dot2 {
    top: auto;
    bottom: 0;
    animation-delay: -1s
}

@keyframes sk-rotate {
    100% {
        transform: rotate(360deg)
    }
}

@keyframes sk-bounce {
    0%, 100% {
        transform: scale(0)
    }
    50% {
        transform: scale(1)
    }
}

.bg-home {
    padding: 160px 0 50px 0;
    background-color: rgba(37, 88, 171, .05)
}

@media (max-width: 991.98px) {
    .bg-home {
        padding: 140px 0 95px
    }
}

.home-center {
    display: table;
    width: 100%;
    height: 100%
}

.home-desc-center {
    display: table-cell;
    vertical-align: middle
}

.bg-home2 {
    padding: 180px 0 150px 0;
    background: linear-gradient(135deg, #d3409e, #11b9e8 100%)
}

.bg-home2 .bg-overlay {
    background: url("../images/home/home2-bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat
}

.home-content {
    position: relative;
    z-index: 1
}

.bg-home3 {
    padding-top: 168px;
    position: relative;
    background-color: #d3409e
}

.bg-home3 .bg-overlay {
    background: url("../images/home/home3-bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat
}

.bg-home4 {
    padding: 230px 0 215px 0;
    background: #f8f9fa
}

@media (max-width: 991.98px) {
    .bg-home4 {
        padding: 170px 0 95px 0
    }
}

.bg-home4 .bg-overlay {
    background: url("../images/home/home4-bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat
}

.bg-home5 {
    overflow: hidden
}

.bg-home5 .bg-overlay {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 30%, transparent)
}

.hero-slider {
    height: 100vh
}

.hero-slider .swiper-slide {
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center
}

.hero-slider .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.hero-slider .swiper-pagination-bullet {
    width: 8px;
    height: 30px;
    background-color: #fff;
    border-radius: 5px
}

.hero-slider .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #d3409e
}

.bg-home6 {
    position: relative;
    padding: 280px 0 260px 0;
    background-image: url("../images/home/home-6.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat
}

@media (max-width: 767.98px) {
    .bg-home6 {
        padding: 225px 0 220px 0
    }
}

.bg-home6 .bg-overlay {
    background-color: rgba(0, 0, 0, .7)
}

iframe {
    max-width: 100%;
    border: 9px solid rgba(255, 255, 255, .3);
    border-radius: 5px 5px 5px 5px
}

.service-box {
    padding: 28px 29px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    transition: all .5s ease;
    box-shadow: 0px 0px 15px #eee;
}

.service-box .service-icon {
    font-size: 44px;
    color: #d3409e
}

.service-box .service-content p {
    color: #9da9bb
}

.service-box .service-content a {
    color: #d3409e
}

.service-box:before {
    content: "";
    height: 80px;
    width: 80px;
    position: absolute;
    top: 0px;
    left: 0px;
    transition: all .5s ease;
    z-index: -1
}

.service-box:after {
    content: "";
    height: 0px;
    width: 0px;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 10%;
    transition: all .5s ease;
    z-index: -1
}

.service-box:hover {
    color: #fff
}

.service-box:hover .service-icon {
    color: #fff
}

.service-box:hover .service-content {
    color: #fff
}

.service-box:hover .service-content p {
    color: rgba(255, 255, 255, .7)
}

.service-box:hover .service-content a {
    color: #fff
}

.service-box:hover:before {
    height: 100%;
    width: 100%;
    background-color: #d3409e
}

.right-icon {
    line-height: 0;
    font-size: 22px
}

.features-box .features-icon {
    background: #d3409e;
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    color: #fff;
    border-radius: 6px;
    font-size: 30px;
    position: relative
}

.features-box .features-icon::before {
    content: "";
    height: 60px;
    width: 60px;
    background: rgba(37, 88, 171, .1);
    position: absolute;
    left: 7px;
    top: -16px;
    transform: rotate(20deg);
    z-index: -1;
    border-radius: 6px
}

.team-box {
    transition: all .4s ease;
    overflow: hidden
}

.team-box .team-img {
    position: relative
}

.team-box .team-img img {
    width: 100%;
    border-radius: 6px
}

.team-box .team-overlay {
    background-color: rgba(0, 0, 0, .7);
    position: absolute;
    transition: all .5s ease-in-out 0s;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    opacity: 0;
    overflow: hidden;
    border-radius: 8px
}

.team-box .team-overlay .team-content {
    position: absolute;
    bottom: -60px;
    left: 0px;
    right: 0px;
    padding-left: 30px;
    padding-right: 30px;
    transition: all .5s ease-in-out 0s;
    opacity: 0
}

.team-box .team-overlay .team-social-icon a {
    height: 35px;
    width: 35px;
    display: inline-block;
    line-height: 33px;
    font-size: 14px;
    color: #fff;
    border: 1px solid #fff;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 50%;
    transition: all .5s
}

.team-box .team-overlay .team-social-icon a:hover {
    color: #fff;
    background-color: #d3409e;
    border: 1px solid #d3409e
}

.team-box:hover .team-overlay {
    opacity: 1;
    visibility: visible
}

.team-box:hover .team-content {
    bottom: 50%;
    transform: translateY(50%);
    opacity: 1
}

.subscribe-form input {
    padding: 14px 20px;
    width: 100%;
    font-size: 16px;
    color: #2a303b;
    border: none;
    outline: none;
    padding-right: 155px;
    padding-left: 30px;
    border-radius: 30px;
    box-shadow: 0 3px 8px rgba(42, 48, 59, .1)
}

.subscribe-form .btn {
    position: absolute;
    right: 0px;
    outline: none;
    border-radius: 30px;
    padding: 13px 38px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    font-size: 16px
}

@media (min-width: 992px) {
    .cta-img {
        position: relative
    }

    .cta-img::before {
        content: "";
        position: absolute;
        background-color: rgba(42, 48, 59, .07);
        right: -40px;
        width: 60px;
        height: 2px;
        top: 50%
    }
}

.counter-box .counter-icon {
    background: rgba(255, 255, 255, .03);
    height: 90px;
    width: 90px;
    border-radius: 50%;
    line-height: 100px;
    text-align: center;
    font-size: 46px;
    margin: 0 auto;
    box-shadow: 0 3px 0px rgba(255, 255, 255, .2)
}

p .counters {
    padding: 20px;
    border-radius: 10px
}

.counter {
    letter-spacing: .125rem;
    line-height: 1;
    position: relative;
    display: flex;
    overflow: hidden;
    justify-content: center;
    height: 2.4rem;
    font-size: 2.4rem;
    font-weight: 400
}

.counter > span {
    z-index: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: transform 2s ease;
    transform: translateY(0);
    line-height: 1
}

.counter > span span {
    flex: 0 0 100%;
    height: 100%
}

.testi-box .testi-content {
    padding: 30px;
    box-shadow: 2px 2px 10px rgba(42, 48, 59, .08);
    border-radius: 6px;
    position: relative;
    background-color: #fff
}

.testi-box .testi-content:after {
    content: "";
    width: 20px;
    height: 20px;
    background-color: #fff;
    position: absolute;
    bottom: -10px;
    left: 20%;
    transform: rotate(45deg);
    box-shadow: 16px 14px 17px rgba(42, 48, 59, .08)
}

.testi-box .testi-user img {
    height: 48px;
    width: 48px
}

.testi-slider .swiper-pagination-bullets {
    bottom: -10px
}

.testi-slider .swiper-pagination-bullets .swiper-pagination-bullet {
    width: 14px;
    height: 5px;
    border: none;
    background-color: rgba(37, 88, 171, .8);
    margin: 5px 7px;
    border-radius: 20px
}

.testi-slider .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #d3409e
}

.blog-box {
    border: none;
    box-shadow: rgba(0, 0, 0, .05) 0px 8px 24px
}

.blog-box .blog-img {
    position: relative;
    overflow: hidden;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.blog-box .blog-img img {
    transition: all .5s ease
}

.blog-box .blog-img .blog-tag {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #d3409e;
    padding: 5px 10px;
    color: #fff;
    transition: all .5s ease;
    z-index: 1
}

.blog-box .blog-img::after {
    content: "";
    background-color: rgba(255, 127, 81, 0);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all .5s ease
}

.blog-box:hover .blog-img img {
    transform: scale(1.1)
}

.blog-box:hover .blog-img .blog-tag {
    left: 24px
}

.blog-box:hover .blog-img::after {
    background-color: rgba(255, 127, 81, .45)
}

.Contact-info .icon {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 18px;
    border-radius: 3px;
    text-align: center;
    color: #d3409e;
    background-color: rgba(37, 88, 171, .08)
}

.footer {
    padding: 60px 0 45px;
    position: relative
}

.footer .company-sub-menu li {
    padding: 8px 0
}

.footer .company-sub-menu li a {
    transition: all .5s;
    color: #9da9bb
}

.footer .company-sub-menu li a:hover {
    color: #d3409e;
    margin-left: 5px
}

.footer-social-list a {
    border: 1px solid #d9dee5;
    height: 40px;
    width: 40px;
    display: inline-block;
    border-radius: 50%;
    line-height: 40px;
    text-align: center;
    color: #bbc3d0;
    font-size: 18px;
    transition: all .3s
}

.footer-social-list a:hover {
    background: #d3409e;
    color: #fff;
    border-color: #d3409e;
    box-shadow: 0 0 2px 7px rgba(37, 88, 171, .15)
}

.shape {
    position: absolute;
    pointer-events: none;
    right: 0;
    bottom: 0px;
    left: 0;
}

#home {
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-duration: 1s;

    animation-name: fadeInUp;
}